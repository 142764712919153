import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/hihkH8p-7-M">
    <SEO title="Jesus is Superior - Hebrews" />
  </Layout>
)

export default SermonPost
